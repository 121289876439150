<template>
  <div class="domains">
    <page-block :title="$t('title.domains')" class="domains__block domains__block--list">
      <transition name="fade" mode="out-in">
        <base-loader v-if="isDomainsLoading" />
        <domains-table v-else-if="domainsList.length" :dataset="domainsList" />
        <base-alert v-else-if="domainsError" class="domains__card domains__card--alert">
          {{ domainsError }}
        </base-alert>
        <main-card v-else class="domains__card domains__card--empty standart-text">
          {{ $t('empty.text', { itemtype: $t('empty.itemtype.domain') }) }}
          <base-button slot="bodyEnd" :to="{ name: 'domainsOrder' }">
            {{ $t('orderDomain') }}
          </base-button>
        </main-card>
      </transition>
      <base-button v-if="domainsList.length" slot="action" :to="{ name: 'domainsOrder' }">
        {{ $t('orderDomain') }}
      </base-button>
    </page-block>
    <page-block
      v-if="dnsList.length"
      :title="
        (isDnsHostLoading || dnsList.length) && !dnsHostError
          ? $t('title.dnsHosting')
          : $t('title.dnsHostAdd')
      "
      class="domains__block domains__block--list"
    >
      <transition name="fade" mode="out-in">
        <base-loader v-if="isDnsHostLoading" />
        <dns-service-table v-else-if="dnsList.length" :dataset="dnsList" />
        <base-alert v-else-if="dnsHostError" class="domains__card domains__card--alert">
          {{ dnsHostError }}
        </base-alert>
        {{ $t('empty.text', { itemtype: $t('empty.itemtype.dnshostdomain') }) }}
      </transition>
    </page-block>
    <page-block
      :title="
        (isDnsHostLoading || dnsList.length) && !dnsHostError
          ? $t('title.dnsHost')
          : $t('title.dnsHostAdd')
      "
      class="domains__block domains__block--list"
    >
      <transition name="fade" mode="out-in">
        <base-loader v-if="isDnsHostLoading" />
        <dns-host-table v-else-if="dnsDomainsList.length" :dataset="dnsDomainsList" />
        <base-alert v-else-if="dnsHostError" class="domains__card domains__card--alert">
          {{ dnsHostError }}
        </base-alert>
        <dns-host-add v-else-if="!dnsList.length" class="domains__card domains__card--add" />
        <main-card v-else class="domains__card domains__card--empty standart-text">
          {{ $t('empty.text', { itemtype: $t('empty.itemtype.dnshostdomain') }) }}
          <base-button v-if="toolNewDomain" slot="bodyEnd" @click="addDomain">
            {{ $t('addDomain') }}
          </base-button>
        </main-card>
      </transition>
      <base-button v-if="toolNewDomain && dnsDomainsList.length" slot="action" @click="addDomain">
        {{ $t('addDomain') }}
      </base-button>
    </page-block>
  </div>
</template>

<script>
import DomainsTable from '../../../components/DomainsTable.vue';
import DnsServiceTable from '../../../components/DnsServiceTable.vue';
import DnsHostTable from '../../../components/DnsHostTable.vue';
import DnsHostAdd from '../../../components/DnsHostAdd.vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import OrderConfig from '@/components/Configurator/OrderConfig.vue';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import BaseConfigurator from '@/models/base/BaseConfigurator';
import { isEqual } from 'lodash';
import { BillMgrTool } from '@/models/BillMgr/Tools';
import { DnsMgrTool } from '@/models/DnsMgr/DnsMgrTools';

export default {
  name: 'DomainsList',
  components: {
    DomainsTable,
    DnsServiceTable,
    DnsHostTable,
    DnsHostAdd,
  },
  mixins: [showErrorModal],
  data() {
    return {
      moduleDnsHost: 'moduleDomains.moduleDomainsDnsHost',
      modal: null,
      domainsError: '',
      dnsHostError: '',
    };
  },
  computed: {
    isDomainsLoading() {
      return this.$store.state.moduleDomains.moduleDomainsDomains.isLoading;
    },
    isDnsHostLoading() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.isLoading;
    },
    domainsList() {
      return this.$store.state.moduleDomains.moduleDomainsDomains.list;
    },
    dnsList() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.dnsList;
    },
    dnsDomainsList() {
      return this.$store.state.moduleDomains.moduleDomainsDnsHost.list;
    },
    moduleState() {
      const splitted = this.moduleDnsHost.split('.');
      const path = splitted.reduce((acc, item) => {
        return acc[item];
      }, this.$store.state);
      return path;
    },
    modulePath() {
      return this.moduleDnsHost.replaceAll('.', '/');
    },
    dnsHostTools() {
      return this.moduleState.tools;
    },
    dnsMgrTools() {
      return this.moduleState.dnsMgrTools;
    },
    toolNewDomain() {
      if (!this.dnsMgrTools || !this.dnsMgrTools.new) return null;
      return this.dnsMgrTools.new;
    },
    dnsListHead() {
      if (!this.moduleState.dnsList.length) return null;
      return this.moduleState.dnsList[0];
    },
    dnsAuth() {
      // console.log(this.moduleState.dnsAuth);
      return this.moduleState.dnsAuth;
    },
  },
  mounted() {
    this.fetchList();
  },
  beforeRouteLeave(_to, _from, next) {
    this.reset();
    next();
  },
  methods: {
    fetchList() {
      this.$store
        .dispatch('moduleDomains/moduleDomainsDomains/fetchList')
        .then(() => (this.domainsError = ''))
        .catch(e => (this.domainsError = e.msg));
      this.$store
        .dispatch('moduleDomains/moduleDomainsDnsHost/fetchList')
        .then(() => (this.dnsHostError = ''))
        .catch(e => (this.dnsHostError = e.msg));
    },
    reset() {
      this.$store.dispatch('moduleDomains/moduleDomainsDomains/reset');
      this.$store.dispatch('moduleDomains/moduleDomainsDnsHost/reset');
    },
    async addDomain() {
      this.showLoadingModal();
      let params = {
        func: this.toolNewDomain.func,
        auth: this.dnsAuth,
      };
      const data = await this.getAddForm(this.toolNewDomain, params);
      if (data === 'cancel' || data === 'fail') this.$modals.close(this.modal.name);
      else {
        const res = await this.sendAddForm(this.toolNewDomain, data);
        this.showResModal(res);
        this.$store.dispatch(`${this.modulePath}/updateList`);
        this.$store.dispatch(`${this.modulePath}/fetchList`);
      }
    },
    getAddForm(tool, params = {}) {
      return new Promise(resolve => {
        if (!tool || !(tool instanceof BillMgrTool || tool instanceof DnsMgrTool)) resolve('fail');
        const func =
          tool instanceof BillMgrTool ? 'fetchBillMgrToolAction' : 'fetchDnsMgrToolAction';
        this.$store
          .dispatch(`${this.modulePath}/${func}`, params)
          .then(async data => {
            const { fields, hidefields, model, slist } = data;
            const config = new BaseConfigurator({
              customfields: fields,
              hidefields,
              model,
              slist,
            });
            const res = await this.showEditModal(config);
            if (res === 'cancel') resolve('cancel');
            else if (res === 'fail') resolve('fail');
            else {
              params = { ...params, ...res };
              resolve(params);
            }
          })
          .catch(e => {
            this.showError(e);
            resolve('fail');
          });
      });
    },
    sendAddForm(tool, payload = {}) {
      const params = {
        auth: this.dnsAuth,
        id: this.dnsListHead.id,
        ...payload,
      };
      return new Promise(resolve => {
        if (!tool || !(tool instanceof BillMgrTool || tool instanceof DnsMgrTool)) resolve('fail');
        const func = tool instanceof BillMgrTool ? 'sendBillMgrToolAction' : 'sendDnsMgrToolAction';
        this.$store
          .dispatch(`${this.modulePath}/${func}`, params)
          .then(data => {
            if (data.ok) resolve('success');
            else resolve('fail');
          })
          .catch(e => {
            this.showError(e);
            resolve('fail');
          });
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', null);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },
    showEditModal(config, props = {}) {
      return new Promise(resolve => {
        if (!config || !(config instanceof BaseConfigurator)) resolve('fail');
        if (!this.modal) this.makeModal(props);
        let formData = {};
        let formDataBackup = null;
        let isReady = false;
        Vue.set(this.modal, 'props', { period: 12, configurator: config });
        Vue.set(this.modal, 'text', null);
        Vue.set(this.modal, 'on', {
          init: data => {
            Object.assign(formData, data);
            formDataBackup = { ...formData };
          },
          ready: data => {
            Object.assign(formData, data);
            isReady = true;
            if (!isEqual(formData, formDataBackup)) {
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            }
          },
          notready: data => {
            Object.assign(formData, data);
            isReady = false;
            Vue.set(this.modal.footer.confirm.props, 'disabled', true);
          },
          change: data => {
            Object.assign(formData, data);
            let hasChanges = !isEqual(formData, formDataBackup);
            Vue.set(this.modal.footer.confirm.props, 'disabled', !hasChanges || !isReady);
          },
        });
        Vue.set(this.modal, 'component', OrderConfig);
        Vue.set(this.modal, 'closable', true);
        Vue.set(this.modal, 'footer', {
          confirm: {
            props: { title: this.$t('submit'), disabled: true },
            on: { click: () => resolve(formData) },
          },
          cancel: {
            props: { title: this.$t('cancel') },
            on: { click: () => resolve('cancel') },
          },
        });
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', this.$t(`modal.res.${res}`));
      Vue.set(this.modal.footer, 'centered', true);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close'), theme: 'filled' },
          on: { click: () => this.$modals.close() },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "dnsHost": "Управление DNS-зоной",
      "dnsHosting": "Управление DNS-услугой",
      "domains": "Зарегистрированные домены",
      "dnsHostAdd": "Добавить услугу поддержки DNS"
    },
    "orderDomain": "Купить домен",
    "addDnsService": "Добавить услугу",
    "addDomain": "Добавить домен",
    "orderHost": "Добавить днс-хостинг",
    "modal": {
      "res": {
        "success": "Запрос выполняется. Пожалуйста, дождитесь обновления данных.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    },
    "empty": {
      "text": "На данный момент у вас нет ни одного %{itemtype}",
      "itemtype": {
        "domain": "зарегистрированного домена",
        "dnshosttariff": "днс-хостинга",
        "dnshostdomain": "добавленного домена"
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.domains {
  &__block {
    &--add {
      max-width: 830px;
    }
  }
  &__card {
    max-width: 750px;
  }
}
</style>
